<template>
  <section class="tables" >
 
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              Liste des objectifs
            </div>
            <nav aria-label="breadcrumb" class=" float-right">
              <ol class="breadcrumb">
                <router-link to="addobjectifs">
                  <li class="breadcrumb-item btn btn-primary">
                    Affecter un objectif
                  </li>
                </router-link>
              </ol>
            </nav>
            <div class="table-responsive">
              <input
                value=""
                class="form-control search-input"
                placeholder="Recherche par délégué"
                type="text"
                v-model="search"
              />
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Délélgué</th>
                    <th scope="col">Titre</th>
                    <th scope="col">Date début</th>
                    <th scope="col">Date fin</th>
                    <th scope="col">Chiffre</th>
                    <th scope="col">Nombre de visites</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(objectif, index) in searchFunction" :key="index">
                    <td>
                      <b>{{ objectif.user_id["name"] }}</b>
                    </td>
                    <td>{{ objectif.nomperiode }}</td>
                    <td>{{ objectif.date_debut | formatDate }}</td>
                    <td>{{ objectif.date_fin | formatDate }}</td>
                    <td>{{ objectif.chiffre }}</td>
                    <td>{{ objectif.nb_visites }}</td>
                    <td>
                      <i
                        class="mdi mdi-pen icone text-success"
                        title="Modifier l'objectif"
                        @click="choose(objectif._id)"
                      >
                      </i>
                      <i
                        class="mdi mdi-delete icone text-danger"
                        title="Supprimer l'objectif"
                        @click.prevent="deletobjectif(objectif._id)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { HTTP } from '@/axios';
import Swal from "sweetalert2";
import Toast from "sweetalert2";
export default {
  name: "basicTables",
  data() {
    return {
      objectifs: [],
      search: "",
    };
  },
  created() {
    this.getobjectif();
  },
  computed: {
    searchFunction() {
      return this.objectifs.filter((item) => {
        return (
          item.user_id["name"]
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
  },
  methods: {
    getobjectif() {
      HTTP
        .get("objectifs/getobjectifs")
        .then((response) => {
          this.objectifs = response.data;
        })
        .then((err) => console.log(err));
    },
    choose(id) {
      this.$router.push("objectifs/modifobjectifs/" + id);
    },
    deletobjectif(id) {
      Swal.fire({
        title: "Êtes-vous sûrs ?",
        text: "Vous voulez supprimé l'objectif",
        icon: "warning",
        cancelButtonText: "Annuler",
        showCancelButton: true,
        confirmButtonColor: "#3e884f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, valider!",
      }).then((result) => {
        if (result.isConfirmed) {
        HTTP
            .delete("deletobjectifs/" + id)
            .then((response) => {
              //this.$htmlToPaper("printNow");
              Toast.fire({
                position: "top-center",
                icon: "success",
                title: "Objectif supprimé",
              });
              this.getobjectif();
            });
        }
      });
    },
  },
};
</script>